import React, { useState , useRef , useEffect} from "react";
import axios from 'axios';
import { Modal, Button } from "react-bootstrap";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PropComponent from "./PropComponent";
import PartnerComponent from "./PartnerComponent";
import CompanyComponent from "./CompanyComponent";
import '../../CssFiles/appTimeline.css';
import {lostReasonMap, loanTypeMap} from '../../utils'

function CRMComponent() {
    const [selectedOption, setSelectedOption] = useState('GLID');
    const [val, setVal] = useState('');
    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [showUI, setShowUI] = useState(false);
    const [wrongCred,setWrongCred] = useState('')
    const [glid,setGlid] = useState('');
    const [mobNo,setMobNo] = useState('');
    const [showInfo,setShowInfo] = useState(false);
    const [piInfo,setPiInfo] = useState([]);
    const [submitErr,setSubmitErr] = useState('');
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate,setToDate] = useState(new Date());
    const [allApp,setAllApp] = useState([]);
    const [appTime,setAppTime] = useState([]);
    const [pdfArr,setPdfArr] = useState([]);
    const [bankArrState,setBankArrState] = useState([]);
    const [respGlid,setRespGlid] = useState('');
    const [isUploaded,setIsUploaded] = useState('');
    const [isAnalysed,setIsAnalysed] = useState('');
    const [isNBR,setIsNBR] = useState('');
    const [show,setShow] = useState(false);
    const [kycArr,setKycArr] = useState([]);
    const [isViewVisible,setIsViewVisible] = useState(false);
    const [constSubmit,setConstSubmit] = useState(false);

    const [uploadedFile, setUploadedFile] = useState(null);
    const [analysedFile,setAnalysedFile] = useState(null);

    const [uploadedText,setUploadedText] = useState('');
    const [analysedText,setAnalysedText] = useState('');
    const [constitutionType,setConstitutionType] = useState('');

    const [docPwd,setDocPwd] = useState('');

    const bankingRef = useRef(null);
    const analysedRef = useRef(null);

    const [selectType,setSelectType] = useState("raw");
    const [isChecked, setIsChecked] = useState(false);
    const [gstArr,setGstArr] = useState([]);
    // const today = new Date().toISOString().split('T')[0];

    useEffect(() => {
        console.log(gstArr)
        if (gstArr["gstin_number"]) {
            checkGSTConditions();
        }
    }, [gstArr]);
    const [showTimelineDetails, setShowTimelineDetails] = useState(false);
    const [selectedAppId, setSelectedAppId] = useState(null);
    const [selectedTimeLender, setSelectedTimeLender] = useState(null);
      

    const formatDate = (uploadDate) => {
        if (uploadDate) {
            const date = new Date(uploadDate);
            const day = String(date.getDate()).padStart(2, '0');
            const month = String(date.getMonth() + 1).padStart(2, '0'); 
            const year = date.getFullYear();
        
            const formattedDate = `${day}-${month}-${year}`;

            return formattedDate;
        }
        
    }

    const handleGlidChange = (event) => {
        setGlid(event.target.value)
    };

    const handleMobChange = (event) => {
        setMobNo(event.target.value)
    };

    const handleUserChange = (event) => {
        setUser(event.target.value);
    };

    const handlePwdChange = (event) => {
        setPwd(event.target.value);
    };

    const handleRadioChange = (event) => {
        setSelectType(event.target.value);
    };

    const checkDateDiff = (strDt) => {
        const [day, month, year] = strDt.split('-');
        const givenDate = new Date(`${year}-${month}-${day}`);
        const today = new Date();

        const timeDiff = today.getTime() - givenDate.getTime();

        const dayDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

        return dayDiff < 15;
    }

    const handleDocPwdChange = (event) => {
        setDocPwd(event.target.value)
    };

    const handleDownloadClick = (url) => {
        if(url !== ""){
            var host = window.location.hostname;

            if(host == 'loans.indiamart.com'){
                var apiURL = "https://loans.indiamart.com/api/v1/viewDoc";
            }
            else {
                var apiURL = "https://dev-loans.indiamart.com/api/v1/viewDoc";
            }

            var inData = {
                "url":url
            };

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: apiURL,
                data: inData,
                timeout:2000
            }).then(function (response) {
                if(response.data.CODE === "200"){
                    window.open(response.data.PDF_URL,'_blank');
                }
            }).catch(function (error) {
                console.log(error);
            });
        }
    };

    const getKYCInfo = async(upload_flag = '') => {             //used in KycComp
        var host = window.location.hostname;

        if(host == 'loans.indiamart.com'){
            var apiURL = "https://loans.indiamart.com/api/v1/getKYC";
        }
        else{
            var apiURL = "https://dev-loans.indiamart.com/api/v1/getKYC";
        }

        var postData = {
            "glid":glid,
            "const_type":upload_flag
        };

        const formData = new FormData();
        for (const key in postData) {
            if (postData.hasOwnProperty(key)) {
                formData.append(key, postData[key]);
            }
        }

        axios({
            method: 'post',
            headers: {  'Content-Type': 'multipart/form-data', },
            url: apiURL,
            data: formData,
            timeout:2000
            }).then(function (response) {
                var resp = response.data;
                if(resp.CODE == "200" && resp.OUTPUT){
                    setKycArr(resp.OUTPUT);
                    setIsViewVisible(true);
                }
                else{
                    setKycArr([]);
                    setIsViewVisible(false);
                }
            }).catch(function (error) {
                console.log(error);
            });
    };

    const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('-'); 
        return new Date(`${year}-${month}-${day}`); 
      };

    const uploadAnalyse = (bankArr) => {
        var isUploadedVar = 'No';
        var isAnalysedVar = 'No';
        var uploadedFlag, analysedFlag = false;
        var rawInitialDuration0, rawInitialDuration1;

        if(bankArr !== undefined && bankArr !== null && bankArr.length > 0){
            for(var i = 0; i < bankArr.length; i++){
                if(bankArr[i]["docStage"] == "raw" && !uploadedFlag){
                    var res = bankArr[i]["duration"].split("to").map(part => part.trim());
    
                    if(checkDateDiff(res[1])){
                        isUploadedVar = 'Yes'
                        rawInitialDuration0 = parseDate(res[0]);
                        rawInitialDuration1 = parseDate(res[1]);
                        uploadedFlag = true;
                    }
                    else{
                        isUploadedVar = 'Old'
                    }
                }
                else if(bankArr[i]["docStage"] === "analyzed" && !analysedFlag){
                    var res = bankArr[i]["duration"].split("to").map(part => part.trim());
    
                    if(checkDateDiff(res[1])){
                        isAnalysedVar = 'Yes'
                        analysedFlag = true;
                    }
                    else{
                        isAnalysedVar = 'Old'
                    }
                }
            }
        }
        
        if(isUploadedVar === 'Yes'){
            setIsNBR('No');
        }
        else{
            setIsNBR('Yes');
        }

        if(isUploadedVar == 'Yes' && isAnalysedVar == 'No'){
            setSelectType("analyzed");
            setFromDate(rawInitialDuration0)
            setToDate(rawInitialDuration1)
        }

        setIsUploaded(isUploadedVar);
        setIsAnalysed(isAnalysedVar);
    };

    const handleSubmitClick = (flag = "") => {
        if(flag === "refresh"){
            const today = new Date();
            setAnalysedText('')
            setUploadedText('')
            setFromDate(today)
            setToDate(today)
            setDocPwd('')
            setIsChecked(false)
            setSelectType("raw")
            if (bankingRef.current) {
                bankingRef.current.value = ''; 
                setUploadedFile(null)
            }

            if (analysedRef.current) {
                analysedRef.current.value = ''; 
                setAnalysedFile(null)
            }
        }
        setConstSubmit(false)
        setConstitutionType('');

        if(mobNo != "" && glid != ""){
            setMobNo('')
        }

        if(user != "" && pwd != "" && (mobNo != "" || glid != "") ){
            setSubmitErr('');
            var host = window.location.hostname;

            if(host == 'loans.indiamart.com'){
                var apiURL = "https://loans.indiamart.com/api/v1/getInfo";
            }
            else if(host == 'dev-loans.indiamart.com'){
                var apiURL = "https://dev-loans.indiamart.com/api/v1/getInfo";
            }
            else{
                var apiURL = "https://dev-loans.indiamart.com/api/v1/getInfo";
            }

            var inData = {
                "user" : user,
                "pwd" : pwd,
                "flag": (glid !== "") ? "glid" : "mob",
                "val" : (glid !== "") ? glid : mobNo
            };

            // if(user != "" && pwd != ""){
            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: apiURL,
                data: JSON.stringify(inData),
                timeout:2000
                }).then(function (response) {
                    setShowInfo(true)
                    var resp = response.data;
                    
                    setAllApp(resp["allApp"]);
                    setAppTime(resp["appTimeline"])
                    setRespGlid(resp["glid"]);
                    setSelectType("raw");
                    setFromDate(new Date())
                    setToDate(new Date())
                    var bankArr = resp["banking"];
                    setBankArrState(bankArr)
                    uploadAnalyse(bankArr)
                    gstApiFunc(() => {
                        if(gstArr["gstin_number"] && gstArr["gstin_number"].length > 5 && gstArr["gstin_number"].charAt(5) === "P"){
                            getKYCInfo("proprietor")
                        }
                        else if(gstArr["gstin_number"] && gstArr["gstin_number"].length > 5 && gstArr["gstin_number"].charAt(5) === "F"){
                            getKYCInfo("partnership")
                        }
                    })
                }).catch(function (error) {
                    console.log(error);
                });
            // }
        }
        else if(user === ""){
            setSubmitErr("Username empty");
        }
        else if(pwd === ""){
            setSubmitErr("Password empty");
        }
        else if(glid === "" || mobNo === ""){
            setSubmitErr("Both fields empty");
        }
    };

    const handleAppTimelineClick = (appLicationId, lender) =>{
        setSelectedAppId(appLicationId);
        setSelectedTimeLender(lender);
        setShowTimelineDetails(true);
    }

    const handleAppTimelineClose = () =>{
        setSelectedAppId(null);
        setSelectedTimeLender(null);
        setShowTimelineDetails(false);
    }

    const handleAuthorizeClick = () => {
        // setShowUI(true) //to be removed
        var host = window.location.hostname;

        if(host == 'loans.indiamart.com'){
            var apiURL = "https://loans.indiamart.com/api/v1/crmAuthorize";
        }
        else if(host == 'dev-loans.indiamart.com'){
            var apiURL = "https://dev-loans.indiamart.com/api/v1/crmAuthorize";
        }
        else{
            // setUser("95071")
            // setPwd("1")
            // setShowUI(true)
            var apiURL = "https://dev-loans.indiamart.com/api/v1/crmAuthorize";
        }

        var inData = {
            "user" : user,
            "pwd" : pwd,
        };

        if(user != "" && pwd != ""){
            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: apiURL,
                data: JSON.stringify(inData),
                timeout:2000
                }).then(function (response) {
                    if(response.data.CODE == "200"){
                        setShowUI(true)
                    }
                    else{
                        setWrongCred(response.data.ERROR)
                    }
                }).catch(function (error) {
                    console.log(error);
                    setWrongCred("Something went wrong. Please try again.")
                });
        }else{
            if(user == ""){
                setWrongCred("Please enter username")
            }
            else if(pwd == ""){
                setWrongCred("Please enter password")
            }
        }
    };

    const handleBankingChange = (event) => {
        const file = event.target.files[0];
        setUploadedFile(file);
    };

    const handleAnalysedChange = (event) => {
        const file = event.target.files[0];
        setAnalysedFile(file);
    }

    const dateToString = (date) => {
        if (!date) return '';
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const day = String(date.getDate()).padStart(2, '0');
        return `${day}-${month}-${year}`;
    };

    const handleUploadBankingClick = () => {
        if (uploadedFile == null) {
            setUploadedText('Please upload the document.')
            return
        }

        setUploadedText('');

        if(docPwd !== ''){
            setDocPwd('');
        }

        var host = window.location.hostname;

        if(host == 'loans.indiamart.com'){
            var apiURL = "https://loans.indiamart.com/api/v1/uploadBanking";
        }
        else{
            var apiURL = "https://dev-loans.indiamart.com/api/v1/uploadBanking";
        }

        var postData = {
            "glid":respGlid,
            "docStage":"raw",
            "docType":"pdf",
            // "docUrl":"abcdef",
            "uploaderId":user,
            "duration":dateToString(fromDate) + " to " + dateToString(toDate),
            "file": uploadedFile,
            "docCredentials":docPwd
        };
        const formData = new FormData();
        for (const key in postData) {
            if (postData.hasOwnProperty(key)) {
                formData.append(key, postData[key]);
            }
        }
        
        axios({
            method: 'post',
            headers: {  'Content-Type': 'multipart/form-data', },
            url: apiURL,
            data: formData,
            timeout:2000
            }).then(function (response) {
                setShowInfo(true)
                var resp = response.data;
                if(resp.CODE == "200"){
                    setUploadedText("Banking Uploaded Successfully.")
                }
                else{
                    setUploadedText("Something went wrong. Please try again.")
                }
                bankingRef.current.value = null;
                setUploadedFile(null);
                handleSubmitClick("refresh");
            }).catch(function (error) {
                console.log(error);
            });
    };

    const handleUploadAnalysedClick = () => {
        if (analysedFile == null) {
            setAnalysedText('Please upload the document.')
            return
        }

        setAnalysedText('');
        if(docPwd !== ''){
            setDocPwd('');
        }

        var host = window.location.hostname;

        if(host == 'loans.indiamart.com'){
            var apiURL = "https://loans.indiamart.com/api/v1/uploadBanking";
        }
        else{
            var apiURL = "https://dev-loans.indiamart.com/api/v1/uploadBanking";
        }

        var fileType = analysedFile.type;

        if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
            fileType = 'xlsx';
        } else if (fileType === 'application/vnd.ms-excel') {
            fileType = 'xls';
        } else if(fileType === 'text/csv'){
            fileType = 'csv';
        } 
        else {
            fileType = 'Unknown';
        }

        var postData = {
            "glid":respGlid,
            "docStage":"analyzed",
            "docType":fileType,
            // "docUrl":"abcdef",
            "uploaderId":user,
            "docCredentials":docPwd,
            "duration":dateToString(fromDate) + " to " + dateToString(toDate),
            "file": analysedFile
        };
        const formData = new FormData();
        for (const key in postData) {
            if (postData.hasOwnProperty(key)) {
                formData.append(key, postData[key]);
            }
        }
        
        axios({
            method: 'post',
            headers: {  'Content-Type': 'multipart/form-data', },
            url: apiURL,
            data: formData,
            timeout:2000
            }).then(function (response) {
                setShowInfo(true)
                var resp = response.data;
                if(resp.CODE == "200"){
                    analysedRef.current.value = null;
                    setAnalysedText("Analysed Doc Uploaded Successfully.")
                }
                else{
                    setAnalysedText("Something went wrong. Please try again.")
                }
                setAnalysedFile();
                handleSubmitClick("refresh");
            }).catch(function (error) {
                console.log(error);
            });
    };

    const handleViewClick = () => {
        setAnalysedText('')
        setUploadedText('')
        setShow(true);
    };

    const gstApiFunc = async() => {
        var host = window.location.hostname;
        if (host == 'loans.indiamart.com') {
            var getgstDataURL = "https://loans.indiamart.com/api/v1/getGSTData";
        }
        else {
            var getgstDataURL = "https://dev-loans.indiamart.com/api/v1/getGSTData";
        }
    
        var getgstData = {
            "glid": glid,
        };
    
        try{
            let response = await axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: getgstDataURL,
                data: JSON.stringify(getgstData),
                timeout: 10000
            });

            var gstValues = response.data.data.values;
            if(response.data.code !== undefined && response.data.code !== null && response.data.code == '200'){
                updateGstArr(gstValues);
            }
            else{
                updateGstArr([]);
            }
        }
        catch (error) {
            console.error("An error occurred in utils gstapi call ", error);
        }
    };

    const checkGSTConditions = () => {
        if (gstArr["gstin_number"] && gstArr["gstin_number"].length > 5) {
            if (gstArr["gstin_number"].charAt(5) === "P") {
                getKYCInfo("proprietor");
            } else if (gstArr["gstin_number"].charAt(5) === "F") {
                getKYCInfo("partnership");
            } else if (gstArr["gstin_number"].charAt(5) === "C") {
                getKYCInfo("company");
            }
        }
    };

    const updateGstArr = (gstValues) => {
        setGstArr(gstValues);
    };

    const handleConstitutionChange = (event) => {
        setConstitutionType(event.target.value)
    };

    const stateValues = {respGlid,setRespGlid,styles,gstArr,user,handleDownloadClick,getKYCInfo,kycArr,isViewVisible}

    return (
        <>
            {
                !showUI &&
                <div style={styles.container}>
                    <div style={styles.authContainer}>
                        <table style={styles.table}>
                            <tbody>
                                <tr>
                                    <td style={styles.label}>Username:</td>
                                    <td>
                                        <input
                                            type="text"
                                            placeholder="Enter Value"
                                            onChange={handleUserChange}
                                            style={styles.input}
                                            maxLength={15}
                                            // defaultValue={"admin@98256"}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td style={styles.label}>Password:</td>
                                    <td>
                                        <input
                                            type="password"
                                            placeholder="Enter Value"
                                            onChange={handlePwdChange}
                                            style={styles.input}
                                            maxLength={20}
                                            // defaultValue={"LENDINGCRM@123456"}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <button onClick={handleAuthorizeClick} style={styles.button}>Authorize</button>

                        { 
                            wrongCred != '' &&
                            <div style={{color:'red',fontSize:'12px',paddingTop:'10px',paddingLeft:'15px',textAlign:'left'}}>{wrongCred}</div>
                        }
                    </div>
                </div>
            }
            {
                showUI &&
                <>
                    <div style={{backgroundColor:'#007BFF',width:'100%',height:'57px',color:'#FFF',display:'flex',justifyContent:'center',alignItems:'center',fontWeight:'bold',fontSize:'24px'}}>CRM Portal</div>

                    <div style={{marginTop:'20px',textAlign:'center'}}>
                        
                        <label>GLID</label>
                        <input type="text" style={{backgroundColor:'#D9D9D9',borderRadius:'8px',height:'40px',width:'150px',marginLeft:'20px',border:'none',paddingLeft:'15px'}} maxLength={12} onChange={handleGlidChange} value={glid}/>

                        <label style={{marginLeft:'80px'}}>Mobile Number</label>
                        <input type="text" style={{backgroundColor:'#D9D9D9',borderRadius:'8px',height:'40px',width:'150px',marginLeft:'20px',border:'none',paddingLeft:'15px'}} maxLength={10} onChange={handleMobChange} value={mobNo}/>

                        <button style={{backgroundColor:'#007BFF',color:'#FFF',borderRadius:'8px',border:'none',padding:'4px 10px',marginLeft:'180px'}} onClick={handleSubmitClick}>SUBMIT</button>

                        <i class="bi bi-arrow-clockwise" style={{float:'right',position:'absolute',fontSize:'24px',right:'100px',display:showInfo ? 'inline-block' : 'none',cursor:'pointer'}} onClick={() => handleSubmitClick("refresh")}></i>
                    </div>

                    {
                        submitErr != '' &&
                        <div style={{color:'red',fontSize:'12px',paddingTop:'10px',paddingLeft:'15px',textAlign:'left'}}>{submitErr}</div>
                    }
                </>
            }
            {
                showInfo && 
                <>
                    <div style={{display: respGlid != '' ? 'flex' : 'none',justifyContent:'center',fontSize:'16px',fontWeight:'700',marginTop:'20px'}}>Showing results for GLID : {respGlid}</div>
                    <div style={{display:'flex',justifyContent:'center'}}>
                        {/* <div style={styles.allSec} id="sec1">
                            <h3 style={{textDecoration:'underline',fontSize:'20px',fontWeight:'bold'}}>PI Information</h3>
                            {piInfo.glusr_id && <p><strong>GLUSR ID:</strong> {piInfo.glusr_id}</p>}
                            {piInfo.full_name.trim() && <p><strong>Full Name:</strong> {piInfo.full_name}</p>}
                            {piInfo.mob_no && <p><strong>Mobile Number:</strong> {piInfo.mob_no}</p>}
                            {piInfo.gst_num && <p><strong>GSTIN:</strong> {piInfo.gst_num}</p>}
                            {piInfo.turnover && <p><strong>GSTIN Turnover:</strong> {piInfo.turnover}</p>}
                            {piInfo.reg_date && <p><strong>GSTIN Reg Date:</strong> {piInfo.reg_date}</p>}
                            {piInfo.pincode && <p><strong>Pincode:</strong> {piInfo.pincode}</p>}
                        </div> */}

                        
                        <div id="allAppList" style={{...styles.midSection,height: isUploaded !== 'No' ? '588px' : '547px',overflowY:'auto',width:'25%'}}>
                            <div style={{fontSize:'18px',fontWeight:'bold',textAlign:'center'}}>All Applications List</div>
                            {allApp && allApp.length > 0 ? (
                                <ol>
                                {allApp.map((app, index) => (
                                    <li key={index} style={{marginTop:'10px',border:'1px solid',padding:'10px',borderRadius:'8px'}}>
                                    <p style={{marginBottom:'0px'}}><strong>Date:</strong> {app.appDate ? formatDate(new Date(app.appDate)) : "N/A"}</p>
                                    <p style={{marginBottom:'0px'}}><strong>Lender Name:</strong> {app.lenderName || 'N/A'}</p>
                                    <p style={{marginBottom:'0px'}}><strong>Status:</strong> {app.status || 'N/A'}</p>
                                    <p style={{marginBottom:'0px'}}><strong>Stage:</strong> {app.stage || 'N/A'}</p>
                                    {app.status == "Rejected" &&
                                    <p style={{marginBottom:'0px'}}><strong>Lost Reason:</strong> {lostReasonMap[app.lostReason] || 'N/A'}</p>}
                                    </li>
                                ))}
                                </ol>
                            ) : (
                                <p style={{textAlign:'center'}}>No applications available.</p>
                            )}
                        </div>
                        
                        <div style={{width:'35%'}} id="sec2">
                            <div id="bureau" style={{...styles.midSection,textAlign:'center'}}>
                                <div style={{fontSize:'18px',fontWeight:'bold'}}>Bureau</div>
                                <div>Pulled: Yes/No</div>
                                <div>Uploaded: Yes/No</div>
                                <div>Bureau Expiring on: Yes/No</div>
                                <div>Bureau Refresh reqd: Yes/No</div>
                                <div>
                                    {/* <label htmlFor="fileUpload" style={{ cursor: 'pointer' }}> */}
                                        {/* <input
                                            type="file"
                                            id="fileUpload"
                                            name="fileUpload[]"
                                            // multiple
                                            accept="application/pdf"
                                            style={styles.fileInput}
                                            // onChange={handleFileChange}
                                        /> */}
                                        <span style={styles.customButton}>
                                            Upload 
                                        </span>
                                    {/* </label> */}
                                    
                                    <button
                                        style={styles.bankingCTA}
                                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007BFF'}
                                        
                                    >
                                        View
                                    </button>
                                </div>
                            </div>

                            <div id="banking" style={{...styles.midSection,textAlign:'center'}}>
                                <div style={{fontSize:'18px',fontWeight:'bold'}}>Banking</div>
                                <div>Uploaded: {isUploaded}</div>
                                <div>Analysed: {isAnalysed}</div>
                                <div style={{ marginTop: '10px' }}>
                                    
                                    <div style={{ marginTop: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        <label htmlFor="dateFrom" style={{ marginRight: '10px', fontSize: '16px', fontWeight: 'bold' }}>
                                            From:
                                        </label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => setFromDate(date)}
                                            dateFormat="dd-MM-yyyy"
                                            maxDate={new Date()}
                                            minDate={new Date("2018-01-01")}
                                            className="custom-input" 
                                            calendarClassName="custom-calendar" 
                                            id="fromDate"
                                        />
                                        <label htmlFor="dateTo" style={{ marginLeft: '10px', fontSize: '16px', fontWeight: 'bold',marginRight: '10px' }}>
                                            To:
                                        </label>
                                        
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => setToDate(date)}
                                            dateFormat="dd-MM-yyyy"
                                            maxDate={new Date()}
                                            minDate={fromDate}
                                            className="custom-input" 
                                            calendarClassName="custom-calendar" 
                                            id="toDate"
                                        />
                                    </div>
                                </div>
                                
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',fontSize:'16px',marginTop:'10px' }}>
                                    <label style={{ display: 'flex', alignItems: 'center', marginRight: '20px' }}>
                                        <input
                                            type="radio"
                                            value="raw"
                                            checked={selectType === "raw"}
                                            onChange={handleRadioChange}
                                            style={{ marginRight: '8px' }}
                                        />
                                        Raw
                                    </label>

                                    <label style={{ display: 'flex', alignItems: 'center' }}>
                                        <input
                                            type="radio"
                                            value="analyzed"
                                            checked={selectType === "analyzed"}
                                            onChange={handleRadioChange}
                                            style={{ marginRight: '8px' }}
                                        />
                                        Analyzed
                                    </label>
                                </div>

                                <div>  
                                    {
                                        selectType === "raw" &&
                                        <div style={{margin:'5px 0px'}}>
                                            <label htmlFor="fileUpload" id="fileUploadLabel" style={{ cursor: 'pointer'}}>
                                                <input
                                                    type="file"
                                                    id="fileUpload"
                                                    name="fileUpload[]"
                                                    // multiple
                                                    accept="application/pdf"
                                                    style={{...styles.fileInput,width:'210px'}}
                                                    onChange={handleBankingChange}
                                                    ref={bankingRef}
                                                />
                                            </label>

                                            
                                            <div style={{margin:'10px 0px'}}>
                                                <label>Doc Pwd:</label>
                                                <input type="text" style={{marginLeft:'5px',borderRadius:'4px',border:'none'}} value={docPwd} onChange={handleDocPwdChange}/>
                                            </div>
                                            
                                            <div style={styles.customButton} onClick={handleUploadBankingClick}>
                                                Upload Banking
                                            </div>

                                            {
                                                uploadedText != '' &&
                                                <div style={{fontSize:'12px',textAlign:'center',color:'#FF1100'}}>{uploadedText}</div>
                                            }
                                        </div>
                                    }

                                    {
                                        selectType === "analyzed" &&
                                        <div style={{margin:'5px 0px'}}>
                                            <label htmlFor="fileUploadAnalysed" style={{ cursor: 'pointer',position:'relative',display:'inline-block'}}>
                                            <input
                                                type="file"
                                                id="fileUploadAnalysed"
                                                name="fileUploadAnalysed[]"
                                                // multiple
                                                accept=".csv, .xls, .xlsx"
                                                style={{...styles.fileInput, width: '210px'}}
                                                onChange={handleAnalysedChange}
                                                ref={analysedRef}
                                            />
                                                
                                            </label>
                                            
                                            
                                            <div style={{margin:'10px 0px'}}>
                                                <label>Doc Pwd:</label>
                                                <input type="text" style={{marginLeft:'5px',borderRadius:'4px',border:'none'}} value={docPwd} onChange={handleDocPwdChange}/>
                                            </div>
                                            
                                            <div style={styles.customButton} onClick={handleUploadAnalysedClick}>
                                                Upload Analysed
                                            </div>

                                            {
                                                analysedText != '' &&
                                                <div style={{fontSize:'12px',textAlign:'center',color:'#FF1100'}}>{analysedText}</div>
                                            }
                                        </div>
                                    }

                                    {
                                        isUploaded !== 'No' &&
                                        <button
                                            // onClick={handleSubmit}
                                            style={styles.bankingCTA}
                                            onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                                            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007BFF'}
                                            onClick={handleViewClick}
                                        >
                                            View
                                        </button>
                                    }

                                </div>

                                <div>New Banking Required: {isNBR}</div>

                                
                            </div>

                            
                        </div>

                        <div id="loanTimeline" style={{...styles.allSec,height: isUploaded !== 'No' ? '588px' : '547px',overflowY:'auto'}}>
                        <div style={{fontSize:'18px',fontWeight:'bold',textAlign:'center'}}>Loan Application Timeline</div>
                        <ol>
                                {
                                    appTime && appTime.length > 0 && 
                                    appTime.filter((app, index, self) =>
                                        index === self.findIndex((t) => t.appId === app.appId)
                                      ).map((app, index) =>
                                    (
                                    <li 
                                        key={index} 
                                        style={{
                                            marginTop: '10px', 
                                            border: '1px solid', 
                                            padding: '10px', 
                                            borderRadius: '8px',
                                            cursor:'pointer'
                                        }}

                                        onClick = {() =>handleAppTimelineClick(app.appId, app.lender)}
                                    >
                                        <p style={{ marginBottom: '0px' }}><strong>Updated On:</strong> {app.date ? formatDate(new Date(app.date)) : "N/A"} </p>
                                        <p style={{ marginBottom: '0px' }}><strong>Application Date:</strong> {app.date ? formatDate(new Date(app.appDate)) : "N/A"} </p>
                                        <p style={{ marginBottom: '0px' }}><strong>Lender Name:</strong> {app.lender || 'N/A'}</p>
                                        <p style={{ marginBottom: '0px' }}><strong>Status:</strong> {app.status || 'N/A'}</p>
                                        <p style={{ marginBottom: '0px' }}><strong>Stage:</strong> {app.stage || 'N/A'}</p>
                                        {app.status == "rejected" &&
                                        <p style={{ marginBottom: '0px' }}><strong>Lost Reason:</strong> {app.lostReason || 'N/A'}</p>}
                                        
                                    </li>
                                ))
                                }
                            </ol>
                        </div> 
                    </div>
                    {/* {constitutionType + constSubmit} */}
                    {
                        ((gstArr["gstin_number"] && gstArr["gstin_number"].length > 5 && gstArr["gstin_number"].charAt(5) === "P") || (constitutionType == 'Proprietorship' && constSubmit)) && 
                        <PropComponent states={stateValues}></PropComponent>
                    }
                    {
                        ((gstArr["gstin_number"] && gstArr["gstin_number"].length > 5 && gstArr["gstin_number"].charAt(5) === "F") || (constitutionType == 'Partnership'  && constSubmit)) && 
                        <PartnerComponent states={stateValues}></PartnerComponent>
                    }
                    {
                        ((gstArr["gstin_number"] && gstArr["gstin_number"].length > 5 && gstArr["gstin_number"].charAt(5) === "C") || (constitutionType == 'Company'  && constSubmit)) && 
                        <CompanyComponent states={stateValues}></CompanyComponent>
                    }

                    {
                        ( (constitutionType === '' || !constSubmit) && (!gstArr["gstin_number"] || gstArr["gstin_number"].charAt(5) != "C" && gstArr["gstin_number"].charAt(5) != "P" &&  gstArr["gstin_number"].charAt(5) != "F")) &&

                        <div id="constType" style={{...styles.midSection,textAlign:'center',width:'32%',margin:'0 auto',minHeight:'100px',marginBottom:'50px'}}>
                            <label style={{fontWeight:'bold'}}>
                                Enter Constitution Type : 
                            </label>

                            <br/>

                            <label className="constTypeRadio">
                                <input
                                    type="radio"
                                    value="Proprietorship"
                                    checked={constitutionType === "Proprietorship"}
                                    onChange={handleConstitutionChange}
                                    style={{marginRight:'2px'}}
                                />
                                Proprietorship
                            </label>

                            <label className="constTypeRadio">
                                <input
                                    type="radio"
                                    value="Partnership"
                                    checked={constitutionType === "Partnership"}
                                    onChange={handleConstitutionChange}
                                    style={{marginRight:'2px'}}
                                />
                                Partnership
                            </label>

                            <label className="constTypeRadio">
                                <input
                                    type="radio"
                                    value="Company"
                                    checked={constitutionType === "Company"}
                                    onChange={handleConstitutionChange}
                                    style={{marginRight:'2px'}}
                                />
                                Company
                            </label>

                            <button style={{backgroundColor:'#007BFF',color:'#FFF',borderRadius:'8px',border:'none',padding:'4px 10px'}} onClick={() => setConstSubmit(true)}>SUBMIT</button>
                        </div>
                    }

                    {showTimelineDetails && (
                       <Modal
                       show={showTimelineDetails}
                       centered
                       dialogClassName="custom-modal-bank" // Custom class for styling
                       onHide={handleAppTimelineClose}
                     >
                       <Modal.Header closeButton>
                         <h3>Application Progress for {selectedTimeLender}</h3>
                       </Modal.Header>
                     
                       <Modal.Body>
                         {appTime && appTime.length > 0 && (
                           appTime
                             .filter((app) => app.appId === selectedAppId && app.event !== "") // Apply the filter
                             .map((app, index) => (
                               <div key={index} className="timeline-container container">
                                 <div className="row">
                                    <div className="col-2" style={{ display: 'flex', alignItems: 'center', flexDirection:'column' }}>
                                    <div className="vertical-line1"></div>
                                        <div className="circle-container">
                                                <div className="big-circle">
                                                    <div className="small-circle">
                                                        <span className="circle-text"></span>
                                                    </div>
                                                </div>
                                        </div>
                                    <div className="vertical-line2"></div>
                                    </div>
                                   <div className="col-10">
                                    <div className="step-details">
                                    
                                     <p style={{color:"#423f3f", fontWeight:'bold'}}>{app.event}.</p>
                                     {app.event === "Lost Reason Updated" ? (
                                       <p className="content">
                                         from "{app.oldVal ? lostReasonMap[app.oldVal] : 'null'}" to "{app.newVal ? lostReasonMap[app.newVal] : 'null'}".
                                       </p>
                                     ): app.event === "Loan Type Updated"? (<p className="content">
                                        from "{app.oldVal ? loanTypeMap[app.oldVal] : 'null'}" to "{app.newVal ? loanTypeMap[app.newVal] : 'null'}".
                                      </p>):
                                     
                                      app.event !== "Application Created"? (<p className="content">
                                         from "{app.oldVal ? app.oldVal : 'null'}" to "{app.newVal ? app.newVal : 'null'}".
                                       </p>
                                     ):(<p></p>)}
                                     <p className="content">
                                     {app.date ? formatDate(new Date(app.date)) : ""}
                                       </p>
                                   </div>
                                   </div>
                                 </div>
                               </div>
                             ))
                         )}
                       </Modal.Body>
                     </Modal>
                     
                     
                    )}
                </>
            }

            {
                isUploaded !== 'No' &&
                <Modal 
                    show={show}
                    centered
                    dialogClassName="custom-modal-bank" // Add a custom class for styling the modal
                    id="custom-modal-bank"
                    onHide={() => setShow(false)}
                >   
                <Modal.Header closeButton>
                    <div style={{fontWeight:'bold',fontSize:'18px'}}>List of PDF</div>
                </Modal.Header>

                <Modal.Body>
                        <table 
                            border="1" 
                            style={{ 
                                width: '100%', 
                                borderCollapse: 'collapse', 
                                textAlign: 'center',
                                border: '2px solid black' // Add a thicker border around the table
                            }}
                        >
                            <thead>
                                <tr style={{...styles.pdfTable}}>
                                    <th style={{...styles.pdfTableHeader}}>S No.</th>
                                    <th style={{...styles.pdfTableHeader}}>Doc Stage</th>
                                    <th style={{...styles.pdfTableHeader}}>Doc Name</th>
                                    <th style={{...styles.pdfTableHeader}}>Duration</th>
                                    <th style={{...styles.pdfTableHeader}}>Upload Date</th>
                                    <th style={{...styles.pdfTableHeader}}>Password</th>
                                    <th style={{...styles.pdfTableHeader}}>View/Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {bankArrState.map((entry, index) => (
                                    <tr style={{border:'1px solid black'}} key={index}>
                                        <td style={{...styles.pdfTableContent}}>{index+1}</td>
                                        <td style={{...styles.pdfTableContent}}>{entry.docStage}</td>
                                        <td style={{...styles.pdfTableContent}}>{entry.docName}</td>
                                        <td style={{...styles.pdfTableContent}}>{entry.duration}</td>
                                        <td style={{...styles.pdfTableContent}}>{formatDate(entry.uploadDate)}</td>
                                        <td style={{...styles.pdfTableContent}}>{entry.pswrd}</td>
                                        <td style={{...styles.pdfTableContent}}> <i class="bi bi-box-arrow-up-right" onClick={() => handleDownloadClick(bankArrState[index]["docUrl"])} style={{cursor:'pointer'}}></i> </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>

            }
        </>    
    );
}

const styles = {
    container: {
        margin: '20px',
        padding: '20px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        maxWidth: '450px',
    },
    table: {
        width: '100%',
        marginBottom: '20px',
    },
    label: {
        paddingRight: '10px',
        fontWeight: 'bold',
    },
    input: {
        width: '100%',
        padding: '8px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    button: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
    },
    radioGroup: {
        marginBottom: '20px',
    },
    radioLabel: {
        display: 'block',
        marginBottom: '10px',
    },
    radio: {
        marginRight: '8px',
    },
    authContainer: {
        textAlign: 'center',
    },
    uiContainer: {
        textAlign: 'left',
    },
    midSection : {
        backgroundColor:'#A2CFFF',
        // height:'200px',
        borderRadius:'8px',
        margin:'20px',
        padding:'20px',
        fontSize:'14px'
    },
    allSec : {
        width:'25%',
        backgroundColor:'#A2CFFF',
        borderRadius:'8px',
        margin:'20px',
        padding:'20px',
        fontSize:'14px'
    },
    bankingCTA:{
        marginBottom: '10px',
        padding: '4px 8px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: '#007BFF', // Button color
        color: '#FFF', // Text color
        fontSize: '16px',
        cursor: 'pointer',
        transition: 'background-color 0.3s ease',
        // margin:'5px'
    },
    // fileInput: {
    //     opacity: 0, 
    //     position: 'absolute', 
    //     zIndex: 1, 
    //     width: '100%', 
    //     height: '100%',
    //     cursor: 'pointer'
    // },
    customButton: {
        display: 'inline-block',
        padding: '4px 8px',
        borderRadius: '5px',
        backgroundColor: '#007BFF',
        color: '#FFF',
        fontSize: '16px',
        cursor: 'pointer',
        margin:'5px'
    },
    datePicker : {
        padding: '4px',
        borderRadius: '5px',
        border: '1px solid #007BFF',
        backgroundColor: '#FFF',
        color: '#333',
        fontSize: '14px',
        marginRight: '10px'
    },
    pdfTableContent : {
        border:'1px solid black'
    },
    pdfTableHeader : {
        border:'2px solid black'
    }
    
};

export default CRMComponent;