import React from "react";
import { useState ,useRef } from "react";
import axios from 'axios';
import { useEffect } from "react";
import { Modal } from "react-bootstrap";

function PropComponent(props){
    const [constitutionType,setConstitutionType] = useState('P');
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedSubOption, setSelectedSubOption] = useState('');
    const [propFile,setPropFile] = useState(null);
    const [isInvalidClick,setIsInvalidClick] = useState('');
    const [show,setShow] = useState(false);
    const [showResidenceProofOptions, setShowResidenceProofOptions] = useState(false);
    const [showBusinessKYCOptions, setShowBusinessKYCOptions] = useState(false);
    const [showBox,setShowBox] = useState(false);
    const [uploadStatus,setUploadStatus] = useState('');
    const [docPwd,setDocPwd] = useState('');

    const stateVals = props.states;
    const styleClass = props.states.styles;
    const propRef = useRef(null);

    const nameToDBArr = {
        "Proprietor Pan":"proprietor_pan",
        "Proprietor Aadhar":"proprietor_aadhar",
        "Proprietor Photo" : "proprietor_photo",
        "Electricity Bill" : "proprietor_electricity_bill",
        "Property Bill":"proprietor_property_bill",
        "Rent Agreement":"proprietor_rent_agreement",
        "GST Certificate":"proprietor_gst_certificate",
        "MSME Certificate":"proprietor_msme_certificate",
        "Udhyam Certificate":"proprietor_udhyam_certificate",
        "Ghumasta License":"proprietor_ghumasta_license",
        "Trade License":"proprietor_trade_license"
    };

    const dbToNameArr = {
        "proprietor_pan": "Proprietor Pan",
        "proprietor_aadhar": "Proprietor Aadhar",
        "proprietor_photo": "Proprietor Photo",
        "proprietor_electricity_bill": "Electricity Bill",
        "proprietor_property_bill": "Property Bill",
        "proprietor_rent_agreement": "Rent Agreement",
        "proprietor_gst_certificate": "GST Certificate",
        "proprietor_msme_certificate": "MSME Certificate",
        "proprietor_udhyam_certificate": "Udhyam Certificate",
        "proprietor_ghumasta_license": "Ghumasta License",
        "proprietor_trade_license":"Trade License"
    };    

    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setSelectedSubOption('')
        setShowResidenceProofOptions(false);
        setShowBusinessKYCOptions(false);
        setShowBox(false);
    };

    const handleSubOptionClick = (subOption,mainOption) => {
        setSelectedOption(mainOption)
        setSelectedSubOption(subOption);
        setShowBox(false);
    };

    const handlePropDocChange = (event) => {
        setPropFile(event.target.files[0]);
    };

    const handlePropUploadClick = (upload_flag = '') => {
        console.log(selectedOption)
        console.log(selectedSubOption)

        if(selectedOption === ""){
            setIsInvalidClick('Please select the option.');
            return;
        }
        else if(selectedOption === "residenceProof" && selectedSubOption === ""){
            setIsInvalidClick("Please select Residence Proof Type.");
            return;
        }
        else if(selectedOption === "bkyc" && selectedSubOption === ""){
            setIsInvalidClick("Please select Business KYC Type.")
            return;
        }
        
        if(propFile === null){
            setIsInvalidClick('Please upload the file.');
            return;
        }

        setIsInvalidClick('');

        var host = window.location.hostname;

        if(host == 'loans.indiamart.com'){
            var apiURL = "https://loans.indiamart.com/api/v1/uploadKYC";
        }
        else{
            var apiURL = "https://dev-loans.indiamart.com/api/v1/uploadKYC";
        }

        var fileType = propFile.type;

        if(fileType === 'application/pdf'){
            fileType = 'pdf';
        }
        else if(fileType === 'image/png'){
            fileType = 'png';
        }
        else if(fileType === 'image/jpeg'){
            fileType = 'jpeg';
        }
        else if(fileType === 'image/jpg'){
            fileType = 'jpg';
        }
        else{
            fileType = 'Unknown';
        }

        var postData = {
            "glid":stateVals.respGlid,
            "docType":fileType,
            "uploaderId":stateVals.user,
            "file": propFile,
            "docDesc":(selectedSubOption !== '' ? nameToDBArr[selectedSubOption] : nameToDBArr[selectedOption]),
            "const_type":upload_flag,
            "docCredentials":docPwd
        };

        const formData = new FormData();
        for (const key in postData) {
            if (postData.hasOwnProperty(key)) {
                formData.append(key, postData[key]);
            }
        }
        
        axios({
            method: 'post',
            headers: {  'Content-Type': 'multipart/form-data', },
            url: apiURL,
            data: formData,
            timeout:2000
            }).then(function (response) {
                var resp = response.data;
                if(resp.CODE == "200"){
                    setUploadStatus("Doc uploaded successfully.")
                }
                else{
                    setUploadStatus("Something went wrong. Please try again.")
                }
                stateVals.getKYCInfo("proprietor")
                propRef.current.value = null;
                setPropFile(null);
                setSelectedOption('');
                setSelectedSubOption('');
                setDocPwd('');
            }).catch(function (error) {
                console.log(error);
            });
    };

    const handleViewClick = () => {
        setShow(true);
        setUploadStatus('');
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getUTCDate()).padStart(2, '0');
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getUTCFullYear();
        return `${day}-${month}-${year}`;
    };

    const handleDocPwdChange = (event) => {
        setDocPwd(event.target.value)
    };

    return (
        <div>
            <div id="kyc" style={{...styleClass.midSection,textAlign:'center',width:'32%',margin:'0 auto',minHeight:'260px',marginBottom:'50px'}}>
                <div style={{fontSize:'18px',fontWeight:'bold'}}>KYC Documents - Proprietor</div>
                <div style={{margin:'10px 0px'}}>
                <label>Choose an option:</label>
                <div
                style={{
                    padding: '4px',
                    borderRadius: '4px',
                    marginLeft: '6px',
                    cursor: 'pointer',
                    position: 'relative',
                    display: 'inline-block',
                }}
                >
                <div
                    style={{
                        backgroundColor: '#FFF',
                        border: '1px solid #ccc',
                        minWidth: '140px',
                        borderRadius: '4px',
                        padding: '4px 8px',
                        textAlign:'left',
                        paddingLeft:'8px'
                    }}
                    onClick={() => setShowBox(!showBox)}
                >
                    {selectedSubOption != '' ? selectedSubOption :  (selectedOption != '' ? selectedOption : 'Select an option')}
                </div>

                <div
                    style={{
                        position: 'absolute',
                        backgroundColor: '#FFF',
                        border: '1px solid #ccc',
                        zIndex: 1,
                        minWidth: '140px',
                        borderRadius: '4px',
                        display: showBox ? 'block' : 'none',
                        textAlign:'left'
                    }}
                >
                    <div className="hoverable" style={{padding:'0px 4px',paddingTop:'4px',paddingLeft:'8px'}} onClick={() => handleOptionClick('Proprietor Pan')}>Proprietor PAN</div>
                    <div className="hoverable" style={{padding:'0px 4px',paddingLeft:'8px'}} onClick={() => handleOptionClick('Proprietor Aadhar')}>Proprietor Aadhar</div>
                    <div className="hoverable" style={{padding:'0px 4px',paddingLeft:'8px'}} onClick={() => handleOptionClick('Proprietor Photo')}>Proprietor Photo</div>

                    <div id="resiProofProp" style={{ position: 'relative', cursor: 'pointer',display:'flex',alignItems:'center',padding:'0px 4px',paddingLeft:'8px' }}> 
                    Residence Proof
                    <div id="resiDrpdownProp" className="resiDrpdownProp">
                        <div className="hoverable" style={{paddingLeft:'8px',paddingTop:'4px'}} onClick={() => handleSubOptionClick('Electricity Bill','proprietor_residence_proof')}>Electricity Bill</div>
                        <div className="hoverable"style={{paddingLeft:'8px'}} onClick={() => handleSubOptionClick('Property Bill','proprietor_residence_proof')}>Property Bill</div>
                        <div className="hoverable" style={{paddingLeft:'8px',paddingBottom:'4px'}} onClick={() => handleSubOptionClick('Rent Agreement','proprietor_residence_proof')}>Rent Agreement</div>
                    </div>
                    <i className="bi bi-caret-down resiDownArrProp" style={{ marginLeft: '8px' }}></i>
                    </div>

                    <div className="hoverable" style={{padding:'0px 4px',paddingLeft:'8px'}} onClick={() => handleOptionClick('GST Certificate')}>GST Certificate</div>

                    <div id="bkycProp" style={{ position: 'relative', cursor: 'pointer',display:'flex',alignItems:'center',padding:'0px 4px',paddingBottom:'4px',paddingLeft:'8px'}}>
                    Business KYC
                    <div id="bkycDrpdownProp" className="bkycDrpdownProp">
                        <div className="hoverable" style={{paddingLeft:'8px',paddingTop:'4px'}} onClick={() => handleSubOptionClick('MSME Certificate','proprietor_business_kyc')}>MSME Certificate</div>
                        <div className="hoverable" style={{paddingLeft:'8px'}} onClick={() => handleSubOptionClick('Udhyam Certificate','proprietor_business_kyc')}>Udhyam Certificate</div>
                        <div className="hoverable" style={{paddingLeft:'8px'}} onClick={() => handleSubOptionClick('Ghumasta License','proprietor_business_kyc')}>Ghumasta License</div>
                        <div className="hoverable" style={{paddingLeft:'8px',paddingBottom:'4px'}} onClick={() => handleSubOptionClick('Trade License','proprietor_business_kyc')}>Trade License</div>
                    </div>
                    <i className="bi bi-caret-down bkycDownArrProp" style={{ marginLeft: '8px' }}></i>
                    </div>
                </div>
                </div>

                </div>

                <div style={{margin:'10px'}}>
                    <input
                        type="file"
                        id="propUpload"
                        name="propUpload"
                        accept="application/pdf, image/png, image/jpeg, image/jpg"
                        style={{...styleClass.fileInput}}
                        onChange={handlePropDocChange}
                        ref={propRef}
                    />

                    {
                        isInvalidClick !== "" &&
                        <div style={{color:'red',fontSize:'12px',paddingTop:'10px',paddingLeft:'15px'}}>{isInvalidClick}</div>
                    }
                    
                    <div style={{margin:'20px 0px 0px'}}>
                        <label>Doc Pwd:</label>
                        <input type="text" style={{marginLeft:'5px',borderRadius:'4px',border:'none'}} value={docPwd} onChange={handleDocPwdChange}/>
                    </div>

                    <div style={{...styleClass.customButton,margin:'20px 10px'}} onClick={() => handlePropUploadClick('proprietor')}>
                        Upload Document
                    </div>

                    {
                        stateVals.isViewVisible && 
                        <button
                        style={styleClass.bankingCTA}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = '#0056b3'}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#007BFF'}    
                        onClick={handleViewClick}
                        >
                            View
                        </button>
                    }
                    
                    {
                        uploadStatus != '' &&
                        <div style={{color:'red',fontSize:'12px',paddingTop:'10px',paddingLeft:'15px'}}>{uploadStatus}</div>
                    }

                <Modal 
                    show={show}
                    centered
                    dialogClassName="custom-modal-bank" // Add a custom class for styling the modal
                    id="custom-modal-bank"
                    onHide={() => setShow(false)}
                >   
                <Modal.Header closeButton>
                    <div style={{fontWeight:'bold',fontSize:'18px'}}>KYC Documents List</div>
                </Modal.Header>

                <Modal.Body>
                        <table 
                            border="1" 
                            style={{ 
                                width: '100%', 
                                borderCollapse: 'collapse', 
                                textAlign: 'center',
                                border: '2px solid black' 
                            }}
                        >
                            <thead>
                                <tr style={{...styleClass.pdfTable}}>
                                    <th style={{...styleClass.pdfTableHeader}}>S No.</th>
                                    <th style={{...styleClass.pdfTableHeader}}>Doc Name</th>
                                    <th style={{...styleClass.pdfTableHeader}}>Doc Description</th>
                                    <th style={{...styleClass.pdfTableHeader}}>Upload Date</th>
                                    <th style={{...styleClass.pdfTableHeader}}>Password</th>
                                    <th style={{...styleClass.pdfTableHeader}}>View/Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stateVals.kycArr && stateVals.kycArr.map((data, index) => (
                                    <tr style={{border:'1px solid black'}} key={index}>
                                        <td style={{...styleClass.pdfTableContent}}>{index+1}</td>
                                        <td style={{...styleClass.pdfTableContent}}>{data.docName}</td>
                                        <td style={{...styleClass.pdfTableContent}}>{dbToNameArr[data.docDesc]}</td>
                                        <td style={{...styleClass.pdfTableContent}}>{formatDate(data.uploadDate)}</td>
                                        <td style={{...styleClass.pdfTableContent}}>{data.pswrd}</td>
                                        <td style={{...styleClass.pdfTableContent}}> <i class="bi bi-box-arrow-up-right" onClick={() => stateVals.handleDownloadClick(data.docUrl)} style={{cursor:'pointer'}}></i> </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                </Modal>
                </div>
            </div>
        </div>
    )
}

export default PropComponent;